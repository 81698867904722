
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Carousel extends Vue {
  @Prop({ default: [] }) private imgList!: any[]
  @Prop() private deviceStatus!: string
  @Prop() private width!: number
  @Prop() private height!: number
  @Prop() private iconSize!: string
  private left = 0
  private time = ''
  @Watch('imgList')
  imgListChange (list: any) {
    if (this.deviceStatus === '2') {
      this.$emit('imgData', [])
      this.time = ''
    } else if (list && list.length > 0) {
      this.$emit('imgData', this.imgList[0].insectResultList)
      this.time = list[0].collectTime
    }
  }

  clickLeft () {
    this.$emit('getLeftData')
  }

  clickRight () {
    this.$emit('getRightData')
  }
}
