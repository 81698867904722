
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDeviceList, SoilHumidityDetail, DeviceInfo } from '@/types/ecologyMonitor'
import { PolygonPathDetail } from '@/types/common'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import Carousel from './Carousel.vue'

@Component({
  components: {
    Carousel
  }
})
export default class InsectMonitor extends Vue {
  // 折叠面板当前展开项
  private activeName = 0
  // 当前地图展示项目
  private projectId = ''
  // 项目列表
  private projectDeviceList: ProjectDeviceList[] = []
  // 当前项目设备列表
  private deviceList = []
  private projectLocation: Array<PolygonPathDetail> = []
  private drawer = false
  private map: AMap.Map | undefined = undefined
  private ploygons: Array<AMap.Polygon> = []
  private markers: Array<AMap.Marker> = []
  private onlineImg = require('@/assets/icon/insectMonitor/normal.svg')
  private onlineImgCursor = require('@/assets/icon/insectMonitor/normal-big.svg')
  private offImg = require('@/assets/icon/insectMonitor/offline.svg')
  private offImgCursor = require('@/assets/icon/insectMonitor/offline-big.svg')

  private drawerData: SoilHumidityDetail | null = null
  private detailInfo: Array<{title: string; value: string; icon: string; status: string}> = []
  private imgData = []
  private imgList = []
  private insectPage = 1
  private insectSize = 1
  private insectTotal = 0

  created () {
    this.getData()
  }

  destroyed () {
    if (this.markers) {
      this.markers.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 左侧项目列表
  getData () {
    this.$axios.get(this.$apis.ecologyMonitor.selectProjectInsectByList).then(res => {
      this.projectDeviceList = res
      if (this.projectDeviceList.length > 0) {
        this.projectId = this.projectDeviceList[0].projectId
        this.getPloygon()
      } else {
        this.$nextTick(() => {
          this.initMap(116.4017, 39.9)
        })
      }
    })
  }

  // 切换项目
  changeProject () {
    const id = this.projectDeviceList[Number(this.activeName)].projectId
    if ((this.activeName || this.activeName === 0) && this.projectId !== id) {
      this.projectId = id
      this.getPloygon()
      this.drawerClose()
    }
  }

  // 获取项目围栏
  getPloygon () {
    this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
      projectId: this.projectId
    }).then((res) => {
      if (res) {
        this.projectLocation = res.locationList || []
        const lng = Number(res.longitude)
        const lat = Number(res.latitude)
        this.$nextTick(() => {
          this.initMap(lng, lat)
        })
      } else {
        this.$message.error('未获取到项目信息')
      }
    })
  }

  getDeviceList () {
    this.$axios.get(this.$apis.ecologyMonitor.selectInsectInfoByPage, {
      projectId: this.projectId
    }).then(res => {
      this.deviceList = res.list || []
      this.drawMarker(this.deviceList)
    })
  }

  // 初始化地图
  initMap (lng: number, lat: number) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [lng, lat]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.ploygons = drawPolygon(this.map, this.projectLocation || [], null, this.drawerClose)
      this.getDeviceList()

      // 点击地图关闭抽屉
      this.map.on('click', this.drawerClose)
    })
  }

  // 地图打点
  drawMarker (list: Array<any>) {
    this.markers = []
    if (list && list.length) {
      // 循坏将设备marker添加到地图
      list.forEach((item: SoilHumidityDetail) => {
        if (item.longitude && item.latitude) {
          const icon = this.getIcon(item.deviceStatus, false)
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          })
          marker.detail = item
          marker.on('click', this.drawerOpen)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 切换设备
  changeDevice (row: DeviceInfo) {
    if (this.map) {
      const e: any = {
        target: {}
      }
      if (row.longitude && row.latitude) {
        e.target = this.map.getAllOverlays('marker').find((item: any) => {
          return item.detail.deviceNumber === row.deviceNumber
        })
        this.map.setCenter([Number(row.longitude), Number(row.latitude)])
      } else {
        this.iconTab()
        e.target = {
          detail: row
        }
      }
      this.drawerOpen(e)
    }
  }

  // 抽屉打开
  drawerOpen (e: any) {
    if ((this.drawerData === null || (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber))) {
      const info = [
        {
          title: '工作状态',
          value: '-',
          icon: 'icon-icon_gongzuozhuangtai',
          status: ''
        },
        {
          title: '灯管状态',
          value: '-',
          icon: 'icon-icon_dengguanzhuangtai',
          status: ''
        },
        {
          title: '加热仓温度(℃)',
          value: '-',
          icon: 'icon-icon_jiarecangwendu',
          status: ''
        },
        {
          title: '环境湿度(%)',
          value: '-',
          icon: 'icon-icon_huanjingshidu',
          status: ''
        },
        {
          title: '光照强度(lx)',
          value: '-',
          icon: 'icon-icon-guangzhaoqiangdu',
          status: ''
        },
        {
          title: '环境温度(°C)',
          value: '-',
          icon: 'icon-icon_huanjingwendu',
          status: ''
        },
        {
          title: '雨控状态',
          value: '-',
          icon: 'icon-icon_yukongzhuangtai',
          status: ''
        },
        {
          title: '光控状态',
          value: '-',
          icon: 'icon-icon_kongkongzhuangtai',
          status: ''
        },
        {
          title: '温控状态',
          value: '-',
          icon: 'icon-icon_wenkongzhuangtai',
          status: ''
        }
      ]
      if (this.drawerData && this.drawerData.deviceNumber !== e.target.detail.deviceNumber) {
        this.drawerClose()
      }
      if (e.target.detail.deviceNumber) {
        this.drawerData = e.target.detail
        // 点击切换大图标
        const iconClick = this.getIcon(e.target.detail.deviceStatus, true)
        if (e.target.detail.longitude && e.target.detail.latitude) {
          e.target.setOffset(new AMap.Pixel(-22, -64))
          e.target.setIcon(iconClick)
        }
        // 工作状态
        info[0].value = e.target.detail.ws === '0' ? '待机' : e.target.detail.ws === '1' ? '工作' : '--'
        // 灯管状态
        info[1].value = e.target.detail.lamp === '0' ? '灭' : e.target.detail.lamp === '1' ? '亮' : '--'
        // 加热仓温度
        info[2].value = e.target.detail.hrt || '--'
        // 环境湿度
        info[3].value = e.target.detail.ah || '--'
        // 光照强度
        info[4].value = e.target.detail.lux || '--'
        // 环境温度
        info[5].value = e.target.detail.at || '--'
        // 雨控状态
        info[6].value = e.target.detail.rps === '0' ? '正常' : e.target.detail.rps === '1' ? '雨控' : '--'
        // 光控状态
        info[7].value = e.target.detail.lps === '0' ? '正常' : e.target.detail.lps === '1' ? '光控' : '--'
        // 温控状态
        info[8].value = e.target.detail.tps === '0' ? '正常' : e.target.detail.tps === '1' ? '温控' : '--'
        this.detailInfo = info
        this.getImgData(e.target.detail.deviceNumber)
      }
    }
    this.drawer = true
  }

  getAllNone () {
    const index = this.detailInfo.findIndex(item => {
      return item.value !== '--'
    })
    return index !== -1
  }

  getStatus (num: string) {
    const arr = ['正常', '偏高', '偏低']
    return num ? arr[Number(num)] : '--'
  }

  // 获取图片
  getIcon (deviceStatus: string, isCurrent: boolean) {
    if (!isCurrent) {
      return (deviceStatus === '1' ? this.onlineImg : this.offImg)
    } else {
      return (deviceStatus === '1' ? this.onlineImgCursor : this.offImgCursor)
    }
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 清空抽屉
      this.iconTab()
      this.drawerData = null
      this.insectPage = 1
    }
  }

  // 替换图标
  iconTab () {
    if (this.map && this.drawerData) {
      const deviceNumber = this.drawerData.deviceNumber || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceNumber === deviceNumber)
      const icon = this.getIcon(this.drawerData.deviceStatus, false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.drawerData.longitude && this.drawerData.latitude) {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
  }

  // 获取虫害轮播图
  getImgData (deviceNumber: string) {
    this.$axios.get(this.$apis.ecologyMonitor.selectInsectImgByPage, {
      deviceNumber: deviceNumber,
      page: this.insectPage,
      size: this.insectSize
    }).then((res) => {
      this.imgList = res.list || []
      this.insectTotal = res.total || 0
    })
  }

  imgDataChange (list: any) {
    this.imgData = list
  }

  // 左翻页
  getLeftData () {
    if (this.drawerData) {
      if (this.insectPage > 1) {
        this.insectPage--
        this.getImgData(this.drawerData.deviceNumber)
      }
    }
  }

  // 右翻页
  getRightData () {
    if (this.drawerData) {
      if (this.insectPage === this.insectTotal) {
        this.insectPage = 1
        this.getImgData(this.drawerData.deviceNumber)
      } else {
        this.insectPage++
        this.getImgData(this.drawerData.deviceNumber)
      }
    }
  }
}
